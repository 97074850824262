<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'GroupList',
              }"
            >
              群組管理
            </b-breadcrumb-item>

            <b-breadcrumb-item active>查看7天內對話紀錄</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <div class="col-12 col-xl-4 mb-1 mb-xl-0 font-weight-bold">
            <h4 class="font-weight-bold">查看7天內對話紀錄</h4>
            <p>群組名稱: {{ this.group.name }}</p>
          </div>
          <div
            class="
              col-12 col-xl-8
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋Line暱稱,Line User,對話內容"
                v-model="keyword"
                v-on:keyup.enter="fetchGroupConversationRecord(currentPage)"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchGroupConversationRecord(currentPage)"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table striped hover responsive :items="records" :fields="fields">
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchGroupConversationRecord"
          ></b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import groupApi from "../../../apis/group";
import moment from "moment";

export default {
  name: "ConversationRecordList",
  data() {
    return {
      showLoading: true,
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      fields: [
        {
          key: "customer.name",
          label: "Line 暱稱",
        },
        {
          key: "source_user_id",
          label: "Line UID",
        },
        {
          key: "message_text",
          label: "對話內容",
        },
        {
          key: "created_at",
          label: "時間",
        },
      ],
      records: [],
      keyword: null,
      group: { name: null },
    };
  },
  mounted() {
    this.fetchGroup();
    this.fetchGroupConversationRecord();
  },
  methods: {
    async fetchGroupConversationRecord(page) {
      this.showLoading = true;

      try {
        const params = {
          page: page || this.currentPage,
        };
        if (this.keyword) params.keyword = this.keyword;

        const { data } = await groupApi.getGroupConversationRecord(
          this.$route.params.group_id,
          params
        );
        this.records = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.log(error);
        alert("取得群組聊天記錄資料錯誤");
      }

      this.showLoading = false;
    },
    async fetchGroup() {
      this.showLoading = true;

      try {
        const { data } = await groupApi.getGroup(this.$route.params.group_id);
        this.group = data;
      } catch (error) {
        console.log(error);
        alert("取得群組資料錯誤");
      }

      this.showLoading = false;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
};
</script>

<style scoped></style>
